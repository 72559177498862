import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchVehicle, setSearchValue, setVehicleDetails } from '../../features/vehicle/vehicleSlice';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const Search = () => {
  const { page_number, searchValue } = useSelector(state => state.vehicle);
  const dispatch = useDispatch();

  const dataSearchFunction = () => {
    dispatch(setVehicleDetails({
      id: '',
      details: {}
    }));
    dispatch(searchVehicle({ searchValue, page_number: 1 }));
  };

  const inputSearchValue = (event) => {
    const value = event.target.value;
    dispatch(setSearchValue({
      type: searchValue.type,
      value: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission

    if (searchValue.value.trim() !== '') {
      dataSearchFunction(); // Call the search function
    }
  };

  const handleChangeSearchType = (event) => {
    const value = event.target.value;
    dispatch(setSearchValue({ type: parseInt(value), value: '' }));
    dispatch(setVehicleDetails({
      id: '',
      details: {}
    }));

    if (searchValue.value !== '') {
      dispatch(searchVehicle({
        searchValue: {
          type: parseInt(value),
          value: searchValue.value
        },
        page_number: 1
      }));
    }
  };

  return (
    <div className='search_container'>
      <form onSubmit={handleSubmit} className='form_container'>
        <input
          className='form_input'
          placeholder={`Search ${searchValue.type === 0 ? 'in all' : searchValue.type === 1 ? 'by rc number' : searchValue.type === 2 ? 'by chassis number' : 'by engine number'}`}
          type="text"
          value={searchValue.value}
          onChange={inputSearchValue}
        />
        <input className='form_input' type="submit" value='Search' />
      </form>
      <FormControl sx={{ width: '40%' }}>
        <InputLabel id="demo-simple-select-label">Search By</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchValue.type}
          label="Search By"
          size='small'
          onChange={handleChangeSearchType}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={1}>RC Number</MenuItem>
          <MenuItem value={2}>Chassis Number</MenuItem>
          <MenuItem value={3}>Engine Number</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Search;
