import React, { useCallback, useState } from 'react'
import { div, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Stack, Alert } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import './vehicle.scss'
import { deleteVehicle } from '../../features/vehicle/vehicleSlice'
import { dateString, timeConverters } from '../../features/common/commonSlice'



function columns(valueKey, value) {
    return <tr key={valueKey} className='vehicle_details_table_body_row_container'>
        <td className='vehicle_details_table_body_content_container_left'>
            <span >{valueKey.toUpperCase().split("_").join(" ")}</span>
        </td>
        <td className='vehicle_details_table_body_content_container_middle'>
            <span >:</span>
        </td>
        <td className='vehicle_details_table_body_content_container_right'>
            <span>{value}</span>
        </td>
    </tr>
}

const VehicleDetailsTable = () => {
    const { vehicleDetails, updateMessage, deleteMessage } = useSelector(state => state.vehicle)
    const { isAdmin, userLoginData } = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const [copyText, setCopyText] = useState(0)

    const setTimeCopiedFunc = useCallback(() => {
        setTimeout(() => {
            setCopyText(0)
        }, 2000)
    })

    const copyToClipboard = useCallback((obj, i) => {
        // const jsonString = JSON.stringify(obj);
        // const newJsonString = jsonString.replace(/"/g, "").split(",").join("\n")
        const newJsonString = `*_Respected Sir/Ma'am_*,\nA vehicle has been traced out by our ground team. The details of the vehicle and customer are as below.\n\n*_Loan No_* *-* ${obj.contract_no === null ? '' : String(obj.contract_no)}\n*_Customer Name_* *-* ${obj.customer_name === null ? '' : String(obj.customer_name).toUpperCase()}\n*_Branch_* *-* ${obj.branch === null ? '' : String(obj.branch).toUpperCase()}\n*_Vehicle No_* *-* ${obj.rc_number === null ? '' : String(obj.rc_number).toUpperCase()}\n*_Chassis No_* *-* ${obj.chassis_number === null ? '' : String(obj.chassis_number).toUpperCase()}\n*_Vehicle Model_* *-* ${obj.mek_and_model === null ? '' : String(obj.mek_and_model)}\n*_Bucket_* *-* ${obj.bkt === null ? '' : String(obj.bkt)}\n*_OD_* *-* ${obj.od === null ? '' : String(obj.od)}\n*_Region_* *-* ${obj.region === null ? '' : String(obj.region)}\n*_Level1_* *-* ${obj.level1 === null ? '' : String(obj.level1).toUpperCase()}\n*_Level2_* *-* ${obj.level2 === null ? '' : String(obj.level2).toUpperCase()}\n*_Level3_* *-* ${obj.level3 === null ? '' : String(obj.level3).toUpperCase()}\n*_Vehicle Location_* *-*\n*_Load Detail_* *-* \n\nWe urgently need you to confirm the status of this vehicle, whether it is to be repossessed or released. *_V K Enterprises_* contact person :\n *_${userLoginData.name}_* *:* ${userLoginData.mobile}.\nThank you for your cooperation.\nBest regards,\n*_V K Enterprises, Maharashtra_*`
        navigator.clipboard.writeText(newJsonString);
        setCopyText(i)
        setTimeCopiedFunc()
    })
    // const copyToClipboard = useCallback((obj, i) => {
    //     let boldText = '';
    //     for (const [key, value] of Object.entries(obj)) {
    //       if (value) {
    //         boldText += `${key.toUpperCase()} _: *${value}*\n`;
    //       }
    //     }
    //     const finalText = `*_Respected Sir/Ma'am_*,\nA vehicle has been traced out by our ground team. The details of the vehicle and customer are as below.\n\n${boldText}\nWe urgently need you to confirm the status of this vehicle, whether it is to be repossessed or released. *_V K Enterprises_* contact person:\n *_${userLoginData.name}_* *:* ${userLoginData.mobile}.\nThank you for your cooperation.\nBest regards,\n*_V K Enterprises, Maharashtra_*`;
    //     navigator.clipboard.writeText(finalText);
    //     setCopyText(i);
    //     setTimeCopiedFunc();
    //   });
      
      

    return (
        updateMessage.message.length !== 0 ?
            <Typography sx={{ mt: 1, p: 2, textAlign: 'center' }} component={Paper}>
                {updateMessage.message}
            </Typography> :
            <div className='vehicle_details_table_wrapper'>
                {
                    deleteMessage.message.length !== 0 && <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert sx={{ display: 'flex', justifyContent: 'center' }} severity={deleteMessage.loading === 'pending' ? 'info' : 'error'}>{deleteMessage.message}</Alert>
                    </Stack>
                }
                < div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', padding: "5px 40px", marginTop: "0px", marginBottom: "0px" }
                }>
                    <h5 className='typography_content'>Vehicle Details</h5>
                    <div>
                        <button className='user_btn' color='secondary' size='small' style={{ textTransform: "capitalize", marginRight: 1 }} onClick={() => copyToClipboard(vehicleDetails.details, 1)} >{copyText === 1 ? "copied!" : "Copy"}</button>
                        {isAdmin && <button className='user_btn' onClick={() => dispatch(deleteVehicle({ vehicle_id: vehicleDetails.id }))} size='small' color='error' style={{ marginLeft: '5px' }}>Delete</button>}
                    </div>
                </div >
                <table className='vehicle_details_table_container'>
                    <tbody className='vehicle_details_table_body_container'>
                        {columns("financer", vehicleDetails.details.financer)}
                        {columns("contract_no", vehicleDetails.details.contract_no)}
                        {columns("customer_name", vehicleDetails.details.customer_name)}
                        {columns("rc_number", vehicleDetails.details.rc_number !== null ? String(vehicleDetails.details.rc_number).replace(/([A-Z]{1,2})(\d{1,2})([A-Z]{1,2})(\d{1,4})/, "$1-$2-$3-$4") : vehicleDetails.details.rc_number)}
                        {columns("mek_and_model", vehicleDetails.details.mek_and_model)}
                        {columns("engine_number", vehicleDetails.details.engine_number)}
                        {columns("chassis_number", vehicleDetails.details.chassis_number)}
                        {columns("branch", vehicleDetails.details.branch)}
                        {columns("region", vehicleDetails.details.region)}
                        {columns("area", vehicleDetails.details.area)}
                        {columns("bkt", vehicleDetails.details.bkt)}
                        {columns("od", vehicleDetails.details.od)}
                        {columns("poss", vehicleDetails.details.poss)}
                        {columns("gv", vehicleDetails.details.gv)}
                        {columns("ses9", vehicleDetails.details.ses9)}
                        {columns("ses17", vehicleDetails.details.ses17)}
                        {columns("tbr", vehicleDetails.details.tbr)}
                        {columns("Ex name", vehicleDetails.details.ex_name)}
                        {columns("level1", vehicleDetails.details.level1)}
                        {columns("level1con", vehicleDetails.details.level1con)}
                        {columns("level2", vehicleDetails.details.level2)}
                        {columns("level2con", vehicleDetails.details.level2con)}
                        {columns("level3", vehicleDetails.details.level3)}
                        {columns("level3con", vehicleDetails.details.level3con)}
                        {columns("level4", vehicleDetails.details.level4)}
                        {columns("level4con", vehicleDetails.details.level4con)}
                        {columns("upload", timeConverters(vehicleDetails.details.upload_time))}
                    </tbody>
                </table>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 40px", marginTop: "0px", marginBottom: "0px" }}>
                    <h5 className='typography_content'>Finance Details</h5>
                    {/* <Button variant='contained' color='secondary' size='small' style={{ textTransform: "capitalize", background: 'rgb(111, 45, 197)' }} onClick={() => copyToClipboard(vehicleSearchBranchDetailsData, 2)} >{copyText === 2 ? "copied!" : "Copy"}</Button> */}
                </div>
                <table className='vehicle_details_table_container'>
                    <tbody className='vehicle_details_table_body_container'>
                        {/* {columns("Branch ID", vehicleDetails.details.branch_id)} */}
                        {columns("Finance name", vehicleDetails.details.branch_name.toUpperCase())}
                        {columns("Contact First", vehicleDetails.details.mobile1)}
                        {columns("Contact Second", vehicleDetails.details.mobile2)}
                        {columns("Contact Third", vehicleDetails.details.mobile3)}
                    </tbody>
                </table>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "5px 40px", marginTop: "0px", marginBottom: "0px" }}>
                    <h5 className='typography_content'>Head Office Details</h5>
                    {/* <Button variant='contained' size='small' style={{ textTransform: "capitalize", background: 'rgb(111, 45, 197)' }} onClick={() => copyToClipboard(vehicleSearchFinanceDetailsData, 3)} >{copyText === 3 ? "copied!" : "Copy"}</Button> */}
                </div>
                <table className='vehicle_details_table_container'>
                    <tbody className='vehicle_details_table_body_container'>
                        {/* {columns("Finance ID", vehicleDetail.finance_code)} */}
                        {columns("Head Office name", vehicleDetails.details.finance_name.toUpperCase())}
                    </tbody>
                </table>
            </div >
    )
}

export default VehicleDetailsTable