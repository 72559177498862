import React, { memo, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FileHeaderCellEditor = ({ label, index, onHeaderCellChange, color }) => {
  const [editing, setEditing] = useState(false);
  const { loadingHeader, defaultHeader, headerEditing } = useSelector(
    (state) => state.excel
  );
  const [inputString, setInputString] = useState(label);
  const [matchedKeys, setMatchedKeys] = useState([]);
  const [values, setValues] = useState(label);
  const [colorValue, setColorValue] = useState(color);
  const inputRef = useRef(null);

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setEditing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value === "") {
        setValues(label);
        toast.error('Cannot enter an empty value', {
          toastId: 'error-toast',
          position: toast.POSITION.TOP_CENTER,
          style: {
            background: 'red',
            color: 'white',
          },
        });
        return;
      } else {
        setValues(event.target.value)
        const inputValue = event.target.value.trim().toLowerCase().split("_").join(" ");
        const isValueIncluded = defaultHeader.includes(inputValue);
        onHeaderCellChange(index, event.target.value, isValueIncluded);
        setColorValue(isValueIncluded);
        setEditing(false);
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setInputString(value);

    const matchedItems = defaultHeader
      .filter((key) => key.includes(value.toLowerCase()))
      .slice(0, 5);
    setMatchedKeys(matchedItems);
  };

  const handleKeyClick = (key) => {
    setInputString(key);
    setMatchedKeys([]);
    setValues(key);
    const isValueIncluded = defaultHeader.includes(key.trim().toLowerCase().split("_").join(" "));
    onHeaderCellChange(index, key, isValueIncluded);
    setColorValue(isValueIncluded);
    setEditing(false);
  };

  if (editing) {
    return (
      <>
        <input
          ref={inputRef}
          style={{ width: "180px", margin: 0 }}
          type="text"
          value={inputString}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={() => setEditing(false)}
          autoFocus
        />

        {matchedKeys.length > 0 && inputRef.current && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "180px",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              boxSizing: "border-box",

              marginTop: "5px",
              top: inputRef.current.getBoundingClientRect().bottom, // Position the box below the input
              left: inputRef.current.getBoundingClientRect().left, // Position the box relative to the input
              
            }}
          >
            {matchedKeys.map((key, index) => (
              <div
                key={index}
                style={{
                  padding: "8px 12px",
                  cursor: "pointer",
                }}
                onMouseDown={() => handleKeyClick(key)}
              >
                {key}
              </div>
            ))}
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          position: "relative",
          fontWeight: 400,
          fontSize: "13px",
        }}
      >
        {loadingHeader[index]}
      </div>
      <div
        onClick={() => headerEditing && setEditing(true)}
        style={{
          position: "relative",
          overflow: "hidden",
          fontWeight: 500,
          fontSize: "14px",
          background: `${colorValue ? "yellow" : ""}`,
        }}
      >
        {values}
      </div>
    </>
  );
};

export default memo(FileHeaderCellEditor);
