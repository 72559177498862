import React, { memo } from 'react'
import { useDispatch } from 'react-redux';
import { cache, setBackdrop, setFileData, setFileHeader, setFileLoadingHeader, setHeaderEditing, setInitialValue } from '../../features/excel/excelSlice';
import { SheetJSFT } from '../../types';
import { read, utils } from 'xlsx';
import Papa from 'papaparse'

const ExcelFileReader = () => {
    const dispatch = useDispatch()
    const chooseFileHandle = (event) => {
        const file = event.target.files[0];

        
        if (!file) {
            return
        }

        dispatch(setInitialValue([]))
        dispatch(setBackdrop(true))
        dispatch(setHeaderEditing(true))
        cache.clearAll()
        // dispatch(setFileLoadingHeader([]))
        // Use SheetJS to read the Excel file
        const reader = new FileReader();
        setTimeout(() => {
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                
                const workbook = read(data, { type: 'array' });
                // console.log(workbook,"workbook")
                // Extract the first worksheet from the workbook
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];

                // Convert the worksheet data to a 2D array using Papa Parse
                const csv = utils.sheet_to_csv(worksheet);
                const parsed = Papa.parse(csv, { header: 1 });

                // Split the data into chunks of the desired size
                const rows = parsed.data;
                dispatch(setFileLoadingHeader(rows[0]))
                dispatch(setFileData(rows))
            }
            reader.readAsArrayBuffer(file);
        }, 100);
    }



    return (
        <input type='file' id='file' accept={SheetJSFT} onChange={chooseFileHandle}></input>
    )
}

export default memo(ExcelFileReader)