import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_URL } from '../../global/production_URL'
import axios from 'axios'


const subtractFinance = createAction('finance/delete/fulfilled')
const newFinance = createAction('finance/create/fulfilled')


export const createOffice = createAsyncThunk(
    'office/create',
    async ({ data }) => {
        console.log(data);
        const options = {
            url: `${API_URL}office/create/new`,
            method: 'POST',
            data: data,
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)


export const allOffice = createAsyncThunk(
    'office/all',
    async ({ searchValue, page_number }) => {
        const options = {
            url: `${API_URL}office/all?page=1000&page_number=${page_number}`,
            method: 'POST',
            data: {
                search_value: searchValue
            },
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)


export const updateOffice = createAsyncThunk(
    'office/update',
    async ({ office_id, data }) => {
        const options = {
            url: `${API_URL}office/update/${office_id}`,
            method: 'PUT',
            data: data,
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)


export const deleteOffice = createAsyncThunk(
    'office/delete',
    async ({ office_id }) => {
        const options = {
            url: `${API_URL}office/delete/${office_id}`,
            method: 'DELETE',
            headers: {
                token: localStorage.getItem('token')
            }
        }
        try {
            const response = await axios(options)
            return response
        } catch (error) {
            return error.message
        }
    }
)

const initialState = {
    totalOffice: [],
    message: '',
    page_number: 1,
    data: [],
    totalSearchData: 0,
    updateMessage: {
        loading: '',
        message: ''
    },
    searchValue: '',
    headOffice: {
        name: '',
        id: ''
    },
    alertOpenOrClose: false
}

export const officeSlice = createSlice({
    name: 'office',
    initialState,
    reducers: {
        setHeadOffice: (state, action) => {
            state.headOffice = action.payload
        },
        setPageNumber: (state, action) => {
            state.page_number = action.payload
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        },
        setUpdateMessage: (state, action) => {
            state.updateMessage = action.payload
        },
        setAlertOpenOrClose: (state, action) => {
            state.alertOpenOrClose = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(allOffice.pending, (state, action) => {
            if (action.meta.arg.page_number < 1)
                state.message = 'Please wait... We are fetching data.'
        }).addCase(allOffice.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.message = ''
                if (action.meta.arg.page_number > 1) {
                    state.totalOffice = state.totalOffice.concat(action.payload.data.response)
                    state.page_number += 1
                } else {
                    state.page_number = 2
                    state.totalSearchData = action.payload.data.total
                    state.totalOffice = action.payload.data.response
                }
            } else {
                state.message = 'Head Office Not Found'
            }
        }).addCase(allOffice.rejected, (state, action) => {
            state.message = `Sorry We can't fetch data. Please try again`
        })



        builder.addCase(updateOffice.pending, (state, action) => {
            state.updateMessage = {
                loading: 'pending',
                message: 'Please wait... We are updating data.'
            }
        }).addCase(updateOffice.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateMessage = {
                    loading: '',
                    message: ''
                }
                state.totalOffice = state.totalOffice.map((office) => {
                    if (office.id === action.meta.arg.office_id) {
                        return { ...office, name: action.meta.arg.data.name }
                    } else {
                        return office
                    }
                })
            } else {
                state.updateMessage = {
                    loading: 'fulfilled',
                    message: 'Head office did not update'
                }
            }
        }).addCase(updateOffice.rejected, (state, action) => {
            state.updateMessage = {
                loading: 'rejected',
                message: `Sorry We can't update data. Please try again`
            }
        })




        builder.addCase(createOffice.pending, (state, action) => {
            state.updateMessage = {
                loading: 'pending',
                message: 'Please wait... We are creating Head Office.'
            }
        }).addCase(createOffice.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateMessage = {
                    loading: '',
                    message: ''
                }
                state.totalOffice = state.totalOffice.concat({ ...action.payload.data, total_branch: 0 })
                state.totalSearchData += 1
                state.message = ''
            } else {
                state.updateMessage = {
                    loading: 'fulfilled',
                    message: 'Head office did not create'
                }
            }
        }).addCase(createOffice.rejected, (state, action) => {
            state.updateMessage = {
                loading: 'rejected',
                message: `Sorry We can't create Head Office. Please try again`
            }
        })




        builder.addCase(deleteOffice.pending, (state, action) => {
            state.updateMessage = {
                loading: 'pending',
                message: 'Please wait... We are deleting Head Office.'
            }
        }).addCase(deleteOffice.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
                state.updateMessage = {
                    loading: '',
                    message: ''
                }
                state.totalOffice = state.totalOffice.filter(office => office.id !== action.meta.arg.office_id)
                state.totalSearchData -= 1
            } else {
                state.updateMessage = {
                    loading: 'fulfilled',
                    message: 'Head office did not delete'
                }
            }
        }).addCase(deleteOffice.rejected, (state, action) => {
            state.updateMessage = {
                loading: 'rejected',
                message: `Sorry We can't delete Head Office. Please try again`
            }
        })



        builder.addCase(subtractFinance, (state, action) => {
            if (action.payload.status === 200) {
                state.totalOffice = state.totalOffice.map(office => {
                    if (office.id === state.headOffice.id) {
                        return { ...office, total_branch: office.total_branch - 1 }
                    } else {
                        return office
                    }
                })
            }
        })


        builder.addCase(newFinance, (state, action) => {
            if (action.payload.status === 200) {
                state.totalOffice = state.totalOffice.map(office => {
                    if (office.id === state.headOffice.id) {
                        return { ...office, total_branch: office.total_branch + 1 }
                    } else {
                        return office
                    }
                })
            }
        })
    }
})

// Action creators are generated for each case reducer function
export const { setPageNumber, setHeadOffice, setSearchValue, setData, setUpdateMessage, setAlertOpenOrClose } = officeSlice.actions

export default officeSlice.reducer