import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allOffice, setData, setSearchValue } from '../../features/office/officeSlice';
import { AddRounded } from '@mui/icons-material';
import { setFormOpenOrClose } from '../../features/common/commonSlice';

const Search = () => {
  const { page_number, searchValue } = useSelector(state => state.office);
  const { isAdmin } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);

  const dataSearchFunction = () => {
    dispatch(allOffice({ searchValue, page_number: 1 }));
  };

  useEffect(() => {
    let fetching = false;
    dispatch(setSearchValue(''));
    dispatch(allOffice({ searchValue: '', page_number: 1 }));
    return () => {
      fetching = true;
    };
  }, []);

  const inputSearchValue = (event) => {
    const value = event.target.value;
    dispatch(setSearchValue(value));
    clearTimeout(timer); // Clear the existing timer

    if (value === '') {
      dispatch(allOffice({ searchValue: '', page_number: 1 }));
    } else {
      setTimer(setTimeout(dataSearchFunction, 700)); // Adjust the delay as needed
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dataSearchFunction();
  };

  return (
    <div className='search_container'>
      <form onSubmit={handleSubmit} className='form_container'>
        <input className='form_input' placeholder='Search Head Office' type="text" value={searchValue} onChange={inputSearchValue} />
        <input className='form_input' type="submit" value='Search' />
      </form>
      {isAdmin && (
        <button className='add_btn' onClick={() => {
          dispatch(setData([]));
          dispatch(setFormOpenOrClose({ type: 'office', open: true }));
        }}><AddRounded /></button>
      )}
    </div>
  );
};

export default Search;
