import React, { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CellMeasurer, Column, Table } from 'react-virtualized'
import { allFinance, allHeader, cache, setBackdrop, setFileData, setFileHeader, setFileLoadingHeader, setHeaderEditing, setUpdatedHeader, setValidHeaderIndex, setVerifiedData } from '../../features/excel/excelSlice'
import './excelFile.css'
import FileHeaderCellEditor from './FileHeaderCellEditor'
import FileCellEditor from './FileCellEditors'
import Backdrop from './BackDrop'
import FileUpload from './FileUpload'
import 'react-virtualized/styles.css'; // only needs to be imported once
import { Box, Button } from '@mui/material'
import ExcelFileReader from './FileReader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';






const FileDataRender = () => {
    const dispatch = useDispatch()
    const { fileHeader, fileData, verifiedData } = useSelector(state => state.excel)

    const handleCellChange = useCallback((row, rowIndex, dataKey, value) => {
        // console.log(row, dataKey, value);
        const updatedRow = { ...row, [dataKey]: value };
        const updatedList = [...fileData];
        // console.log(updatedList, updatedRow);
        updatedList[rowIndex] = updatedRow;
        // console.log(updatedList);
        dispatch(setFileData(updatedList));
    })




    const handleHeaderChange = useCallback((index, value, color) => {
        const updateHeader = fileHeader.map((values, headerIndex) => {
            if (index === headerIndex) {
                return { ...values, label: value, color: color }
            } else {
                return values
            }
        })
        dispatch(setFileHeader(updateHeader));
    });




    const verify = () => {
        cache.clearAll()


        if (fileData.length < 1) {
            return
        }


        const valid = fileHeader.filter((header, index) => { return (String(header.label).trim().toLowerCase().split(' ').join('_') === 'chassis_number' || String(header.label).trim().toLowerCase().split(' ').join('_') === 'rc_number') && header.color === true })

        // if (valid.length < 1) {
        //     return alert('Rc number or Chassis number is required')
           
        // }

        if (valid.length < 1) {
           toast.error('Rc number or Chassis number is required', {
              toastId: 'error-toast',
              position: toast.POSITION.TOP_CENTER,
              style: {
                background: 'red',
                color: 'white',
              },
            });
            return
          }
          




        const duplicateValue = fileHeader.map(item => String(item.label).trim().toLowerCase().split(' ').join('_'))
        const duplicateValidation = duplicateValue.filter((item, index) => duplicateValue.indexOf(item) !== index)
        if (duplicateValidation.length > 0) {
            // alert(`You are entered duplicate  '${String(duplicateValidation[0]).split('_').join(' ')}'  in header`)
            
            toast.error(`You are entered duplicate  '${String(duplicateValidation[0]).split('_').join(' ')}'  in header`, {
                toastId: 'error-toast',
                position: toast.POSITION.TOP_CENTER,
                style: {
                  background: 'red',
                  color: 'white',
                },
              });
            return
        }

        dispatch(setBackdrop(true))

        let data
        const newArrays = fileData.map((row, index, arr) => {
            data = []
            const eachObject = fileHeader.filter((value, index) => {
                if (value.color === true) {
                    data.push(index)
                    return value
                }
            }).reduce((obj, header, i) => {
                obj[String(header.label).trim().toLowerCase().split(" ").join("_")] = Object.values(row)[data[i]] || '';
                return obj;
            }, {})
            return eachObject;
        })

        dispatch(setValidHeaderIndex(data))

        let invalidRecord = []
        let confirmData = []
        // const rcNumber1 = /^[A-Z]{2}\s\d{2}\s[A-Z]{2}\s\d{4}$/
        // const rcNumber2 = /^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/
        // const rcNumber3 = /^[A-Z]{2}\d{2}[A-Z]\d{4}$/
        // const rcNumber4 = /^[A-Z]{2}\s\d{2}\s[A-Z]\s\d{4}$/
        // const rcNumber5 = /^[A-Z]{2}\d{6}$/
        // const rcNumber6 = /^\d{2}BH\d{4}[A-Z]$/
        const rcNumber1 = /^[A-Z]{2}\s\d{1,2}\s[A-Z]{2,3}\s\d{4}$/;
        const rcNumber2 = /^[A-Z]{2}\d{1,2}[A-Z]{2}\d{4}$/;
        const rcNumber3 = /^[A-Z]{2}\d{1,2}[A-Z]\d{4}$/;
        const rcNumber4 = /^[A-Z]{2}\s\d{1,2}\s[A-Z]\s\d{4}$/;
        const rcNumber5 = /^[A-Z]{2}\d{3,5}$/;
        const rcNumber6 = /^\d{2}BH\d{4}[A-Z]$/;
        const char = /^[A-Z]\d{3}$/;
        newArrays.map((val, index) => {
            let rc = ''
            if (val.rc_number !== undefined || val.rc_number !== null) {
                rc = String(val.rc_number).trim().replace(/\s+/g, '').split(/[-:_,/]/).join('').toUpperCase();
            }
            let values = rc.substring(rc.length - 4, rc.length)

            if (char.test(values)) {
                let subValue = rc.slice(0, rc.length - 3)
                let sValue = rc.substring(rc.length - 3, rc.length)
                rc = subValue + "0" + sValue
                
            }

            Object.keys(val).forEach((item) => {
                if (item === "rc_number") {
                    val[item] = rc
                }
                if (item === "chassis_number") {
                    if (val[item] != undefined || val[item] != null) {
                        val[item] = String(val.chassis_number).replaceAll(' ', '').toUpperCase();
                    } else {
                        val[item] = val.chassis_number
                    }
                }
            })
            // if (((rcNumber1.test(rc) || rcNumber2.test(rc)) || (rcNumber2.test(rc) || rcNumber3.test(rc)) || (rcNumber3.test(rc) || rcNumber4.test(rc)) || (rcNumber4.test(rc) || rcNumber5.test(rc)) || (rcNumber5.test(rc) || rcNumber6.test(rc))) || ((val.chassis_number != null || val.chassis_number != undefined) && val.chassis_number != "")) {
            //    debugger
            //     confirmData.push(val)
            // } else {
            //     invalidRecord.push(fileData[index])
            // }
        if (
  (rcNumber1.test(rc) ||
    rcNumber2.test(rc) ||
    rcNumber3.test(rc) ||
    rcNumber4.test(rc) ||
    rcNumber5.test(rc) ||
    rcNumber6.test(rc) ||
    char.test(rc) ||
    /^[A-Z]{2}-[a-zA-Z]{2}-[a-zA-Z]{2}-\d{4}$/.test(rc) ||
    /^[A-Z]{2}\d{6}$/.test(rc)) ||
  ((val.chassis_number != null || val.chassis_number != undefined) && val.chassis_number != "")
) {
  debugger;
  confirmData.push(val);
} else {
  invalidRecord.push(fileData[index]);
}

              
            
        })

        console.log("invalidRecord",invalidRecord)

        // dispatch(setFileHeader([]))
        dispatch(setFileData([]))
        dispatch(setVerifiedData(confirmData))
        dispatch(setHeaderEditing(false))
        if (invalidRecord.length > 0) {
            setTimeout(() => {
                // dispatch(setFileLoadingHeader(invalidRecord[0]))
                dispatch(setFileData(invalidRecord))
                dispatch(setBackdrop(false))
            }, 100);
        } else {
            dispatch(setBackdrop(false))
        }
    }


    return (
        <>
            <Box sx={{ display: 'flex', marginLeft: '40px', alignItems: 'center', height: '30px' }}>
                <ExcelFileReader />
                {
                    (fileData.length > 0 || fileHeader.length > 0) && <>
                        <Button sx={{ marginLeft: '10px' }} variant='contained' size='small' onClick={verify}>Verify Data</Button>
                        <Button sx={{ marginLeft: '10px', marginRight: '10px' }} color='error' variant='contained' size='small'>{fileData.length + '/' + (fileData.length + verifiedData.length)}</Button>
                        <FileUpload />
                    </>
                }
                <Button variant='contained' size='small' sx={{ marginLeft: '10px'}} onClick={async () => {
                    dispatch(setBackdrop(true))
                    await dispatch(allHeader())
                    await dispatch(allFinance())
                    dispatch(setBackdrop(false))
                }}>Refresh</Button>
            </Box>
            <Backdrop />
            {
                (fileData.length > 0 || fileHeader.length > 0)
                && <Table
                    width={fileHeader.length * 195}
                    headerHeight={40}
                    height={660}
                    rowHeight={cache.rowHeight}
                    rowCount={fileData.length}
                    rowGetter={({ index }) => fileData[index]}
                    style={{ overflow: 'auto', margin: 0, marginTop: '15px' }}
                >
                    {fileHeader.map(({ label, dataKey, color }, index) => {
                        return (
                            <Column
                                key={dataKey}
                                dataKey={dataKey}
                                cellRenderer={(props) => (
                                    <FileCellEditor {...props} onCellChange={handleCellChange} />
                                )}
                                headerRenderer={() => { return (<FileHeaderCellEditor label={label} color={color} index={index} onHeaderCellChange={handleHeaderChange} />) }
                                }
                            />
                        )
                    })}
                </Table>}
        </>
    )
}

export default memo(FileDataRender)